.container {
    display: grid;
    grid-auto-columns: 1fr;
    gap: 4% 4%;
    grid-auto-flow: row;
    padding: 10vw;
    min-width: 350px;
  }
.container div{
    border: 2px solid #fff /* rgb(144, 144, 144) */;
    border-radius: 15px;
    -webkit-box-shadow: 11px 7px 20px 0px rgba(0,0,0,0.17); 
    box-shadow: 11px 7px 20px 0px rgba(0,0,0,0.17);
    padding: 4em;
}

.container div button{
    width: 10vw;
    height: 10vw;
    background-color: var(--dark);
    border: none;
    font-size: 5vw;
    color: #fff;
    border-radius: 15px;
}

.container div button:hover {
    background-color: var(--dark-active);
    cursor: pointer;
}

@media screen and (min-width: 601px) {
    .container{
        margin-left:20vw;
        max-width: 80vw;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
        "UpperLeft UpperRight"
        "LowerLeft LowerRight";
    }
}
@media screen and (max-width: 1000px) {
    .container{
        margin-left:30vw;
        max-width: 70vw;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
        "UpperLeft"
        "UpperRight"
        "LowerLeft"
        "LowerRight";
    }
}
  
.UpperLeft { grid-area: UpperLeft; }
.UpperRight { grid-area: UpperRight; }
.LowerLeft { grid-area: LowerLeft; }
.LowerRight { grid-area: LowerRight; }
  