.name{
    font-size: 2vh;
}

.w3-sidebar{background-color: var(--dark);}
@media screen and (min-width: 601px) {
    .w3-sidebar{
        width:20vw;
    }
}
@media screen and (max-width: 1000px) {
    .w3-sidebar{
        width:30vw;
    }
}
.w3-bar-item{font-size: 6.5vw;}
.list{
    list-style: none;
    background-color: var(--dark);
    text-align: left;}
.list a{
    color: white;
    font-size: 1em;
}
.list a:hover {background-color: var(--light-active);}

.isActive {background-color: var(--light-active);}
.isActive p {color: var(--dark);}
.userBtn{
    background: none;
    color: #fff;
    border: 2px solid #fff;
    cursor: pointer;
    margin: 0.4vw;
}

.userBtn:hover{
    background-color: #fff;
    color: var(--dark);
}